import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'adjustments.list',
  data() {
    return {}
  },
  components: {},
  created() {
    let type = this.$route.query ? this.$route.query : {type: 'general'};
    this.fetchSettings(type);
  },
  mounted() {},
  computed: {
    ...mapGetters({
      settings: 'settings/settings',
      isSettingsLoading: 'settings/isSettingsLoading',
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchSettings: 'settings/SETTINGS_REQUEST_LIST_GET',
    }),
    ...mapMutations({
      setSetting: 'settings/SETTINGS_DATA'
    }),
    edit(data) {
      this.setSetting(data)
      this.$router.push({name: 'adjustments.edit', params: {adjustmentId: data.id}}).catch(() => {console.log();});
    }
  },
  destroyed() {},
}
